.fenetre_modal
{
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
}
.nom_image
{
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 10px;
    color: black;
}
.image_fenetre
{
    object-fit: cover;
    max-height: 55vh;
}
.form_modification_image
{
    margin-top:25px;
}
.form_modification_image > div > .form-label
{
    color:black;
}
.fenetre_label_image
{
    color: black;
}
.fenetre_dropdown_button
{
    background-color: #2c3034 !important;
    width: 100%;
}
.fenetre_dropdown_menu
{
    width: 100%;
}
.fenetre_label_categorie
{
    color: black;
}
