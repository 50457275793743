.conteneur_statistiques
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.carte_statistique
{
    padding:10px;
    color: white;
}
.fond_carte
{
    background-color: #2c3034;
}