.conteneur_bouton_selection_images
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.image_bibliotheque
{
    height: 150px;
    cursor: pointer;
    object-fit: cover;
}
.carte_bibliotheque
{
    margin:10px;
    background-color: #2c3034;
}
.alerte_aucune_image
{
    margin-top:25px
}
.bouton_page_image
{
    width: 100%;
}
.dropdown_categorie > button, .dropdown_categorie > div
{
    width: 100%;
}
.dropdown_categorie > button:active
{
    background-color: #cd7f32 !important;
    border: 0px;
}
.dropdown_categorie > button:hover
{
    background-color: #cd7f32 !important;
    border: 0px;
}
.dropdown_categorie > button:focus
{
    background-color: #cd7f32 !important;
    border: 0px;
}
.recherche_categorie
{
    margin: 0px;
}
@media only screen and (max-width: 1000px) {
    .conteneur_bouton_selection_images
    {
        padding: 25px;
    }
    .bouton_page_image
    {
        width: 100%;
        margin-top: 25px;
    }
    .dropdown_categorie
    {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}