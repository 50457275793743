.conteneur_form_reinitialisation
{
    margin-top: 10px;
}
.bouton_retour_page_connexion
{
    display: block;
    width: 60%;
    background-color: #2c3034;
    color: white;
    padding: 10px;
    margin: auto;
    margin-top: 25px;
    border-radius: 10px;
}
.bouton_envoyer_courriel_reinitialisation
{
    display: block;
    width: 100%;
    color: white;
    padding: 10px;
    margin: auto;
    margin-top: 25px;
    border-radius: 10px;
    font-size: 20px;
}