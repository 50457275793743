.titre_utilisateur
{
    color: white;
    margin: 25px;
}
.bordure_action
{
    background-color: #2c3034;
    margin: 0px;
    padding: 15px;
}
.hf_bouton_fiche
{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.titre_actions_evenement
{
    color: white;
}
.image_profil
{
    width: 250px;
    height: 250px;
    border-radius: 125px;
}
.nom_utilisateur
{
    margin: 25px;
    color: white;
}
.section_profil
{
    margin-top: 50px;
}