.conteneur_pied_page
{
    color: white;
    font-size: 16px;
    margin:5px;
    margin-top:25px;
    margin-bottom:50px;
}
.fond_pied_page
{
    background-color: #2c3034;
    padding: 20px;
    border-radius: 5px;
}
.fond_pied_page > p
{
    margin:0px;
}
.fond_pied_page > p > a
{
    color:#E3B94C;
}
.texte_copyright
{
    margin: 0px;
}