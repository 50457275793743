.conteneur_action
{
    margin-top: 25px;
    padding: 0px;
}
.titre_evenement
{
    margin: 25px;
    color: white;
    font-size: 48px;
}
.bordure_action
{
    background-color: #2c3034;
    margin: 0px;
    padding: 15px;
}
.hf_bouton_fiche
{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.image_evenement
{
    max-height: 300px;
    object-fit: cover;
    width: 100%;
}
.titre_actions_evenement
{
    color: white;
}
.bouton_retour_evenement
{
    margin-top: 25px;
}