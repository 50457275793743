.conteneur_navigation
{
    display: flex;
    align-items: center;
    padding-left:50px;
    padding-right:50px;
    background-color:#362D2E;
    color: white;
    justify-content: center;
    vertical-align: middle;
}
.conteneur_navigation_non_connecte
{
    background-color:#362D2E;
    color: white;
    padding: 25px;
}
.hf_titre_logo_icone
{
    font-size: 24px;
    color: #E3B94C;
    font-family: RetroMonoWide;
}
.hf_titre_logo
{
    font-size: 24px;
    margin-top:5px;
    margin-left: 20px;
    color: white;
    text-decoration: none !important;
    font-family: RetroMonoWide;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hf_sous_titre_logo_icone
{
    font-family: RetroMonoWide;
    font-size: 12px;
    color: white;
}
.nav_link
{
    display: flex;
    vertical-align: middle;
}
.nav_link_alerte
{
    position: relative;
    display: flex;
    vertical-align: middle;
    margin-right: 20px;
}
.badge
{
    background-color: #cd7f32 !important;
}
.hf_icone_alerte
{
    margin-right:10px;
}
.hf_icone_deconnexion
{
    margin-right: 5px;
}
.button_disconnect
{
    margin: 10px;
}