.alerte_aucun_evenement
{
    margin-top: 25px;
}
.conteneur_liste_evenement
{
    margin-top:25px;
    vertical-align: middle;
    padding: 25px;
    background-color: #373b3e;
}
.titre_carte_categorie_evenement
{
    color: white;
}
.conteneur_bouton_action
{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hf_bouton_liste
{
    width: 100%;
    min-width: fit-content;
    margin-top: 5px;
    margin-bottom: 5px;
}
.conteneur_bouton_pagination
{
    align-items: center;
}
.bouton_ajout_evenement
{
    margin: 25px;
}
.bouton_precedent_evenement
{
    width: 100%;
}