.zone_depot_image
{
    border: #2c3034 dashed 5px;
    margin-top:25px;
    padding: 25px;
    cursor: pointer;
}
.zone_depot_image_couleur
{
    border: #cd7f32 dashed 5px;
    margin-top:25px;
    padding: 25px;
    cursor: grabbing;
}
.texte_depot_image
{
    margin: 0px;
    color:white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.file_element
{
    display: none;
}