@font-face {
  font-family: RetroMonoWide;
  src: local('RetroMonoWide'), url("./polices/RetroMonoWide.ttf") format('truetype');
}
body
{
  background-color: rgba(24, 17, 14, 0.8);
}
label
{
  color: white;
}
.navbar-brand
{
  display: flex;
  align-items: center;
  width:min-content;
}
.nav-link
{
  color:white !important;
}
.nav-link:hover
{
   color: black;
}
.dropdown-item:hover
{
  background-color:#E3B94C;
}
.dropdown-item:focus
{
  background-color:#E3B94C;
}
p
{
  color: white;
}
svg
{
  fill:white;
}
.btn
{
  background-color: #E3B94C;
  color: #2c3034;
  border: 0px;
}
.btn:hover
{
  background-color: #E3B94C;
  color: #2c3034;
  border: 0px;
}
.btn:focus
{
  background-color: #E3B94C;
  color: #2c3034;
  border: 0px;
}
.btn:disabled
{
  background-color: #E3B94C;
  color: #2c3034;
  border: 0px;
}
a
{
  color: black;
  text-decoration: none;
}
a:hover
{
  color: black;
}
.badge
{
  background-color: #E3B94C !important;
  color: white;
  font-size: 20px;
  color: #2c3034;
  padding: 10px;
  font-weight: bolder;
  cursor: pointer;
}
.MuiFormControl-root
{
  width:100%;
  background-color: #2c3034;
  border-radius: 10px;
  border: 0px !important;
}
.MuiInputBase-root
{
  color: white !important;
}
.MuiInputBase-root:focus
{
  border: 0px;
}
.MuiSvgIcon-root
{
  color: #cd7f32;
}
.form-check-input
{
  background-color: #2c3034;
}
.form-check-input:checked
{
  background-color: #cd7f32;
  border: 0px;
}
.MuiFormControl-root
{
  width:100%;
  background-color: #2c3034;
  border-radius: 10px;
  border: 0px !important;
}
.MuiFormControl-root:focus
{
  width:100%;
  background-color: #2c3034;
  border-radius: 10px;
  border: 0px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-control
{
  background-color: #2c3034;
  color: white;
  border: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-control:focus
{
  background-color: #2c3034;
  color: white;
  border: 0px;
}
.MuiInputBase-root::placeholder
{
  color: darkgrey !important;
}
.form-control::placeholder {
  color: darkgrey !important;
  opacity: 1;
}
.form-select
{
  background-color: #2c3034;
  color: white;
  border: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}