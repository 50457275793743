.conteneur_hf_alerte
{
    vertical-align: middle;
}
.hf_alerte
{
    background-color: #2c3034;
    border: 0px;
    color: white;
    margin-top:25px;
    margin-bottom: 25px;
}
.hf_alerte_icone
{
    margin-right: 10px;
}