.conteneur_bouton_mot_de_passe_oublier
{
    margin:25px;
}
.bouton_mot_de_passe_oublier
{
    color: white;
    background-color: #2c3034;
    padding: 10px;
    border-radius: 10px;
}
.bouton_connexion
{
    width: 100%;
    font-size: 20px;
}