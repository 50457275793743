.conteneur_liste_utilisateur
{
    margin-top: 50px;
    background-color: #373b3e;
}
.titre_carte_utilisateur
{
    color: white;
}
.hf_bouton_liste
{
    width: 80%;
    min-width: fit-content;
    margin:25px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.conteneur_bouton_pagination
{
    align-items: center;
}
.bouton_ajout_utilisateur
{
    margin: 25px;
}
.image_profil
{
    width: 75px;
    height: 75px;
    border-radius: 50px;
}
.bouton_suivant_utilisateur
{
    width: 100%;
}
.bouton_precedent_utilisateur
{
    width: 100%;
}