.conteneur_form_modification_evenement
{
    margin-top:25px;
}
.alerte_ajouter_evenement
{
    margin-top:25px;
}
.bouton_ajout_image
{
    margin:10px;
}
.section_bouton_selection_image
{
    margin-top:25px;
    margin-bottom: 25px !important;
}
.section_selection_image
{
    margin-top:25px;
    margin-bottom: 25px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.carte_image_bibliotheque
{
    cursor: pointer;
    object-fit: cover;
    border: 0px;
    margin:25px;
    background-color: #cd7f32;
}
.carte_image_bibliotheque_selectionne
{
    cursor: pointer;
    object-fit: cover;
    background-color: #cd7f32;
    border: 0px;
    margin:25px;
    border: 10px solid grey;
}
.image_bibliotheque
{
    height: 150px;
}
.bouton_page_image
{
    margin:25px;
}
.description_evenement
{
    height:300px;
}
.dropdown_button
{
    width: 100%;
}
.zone_spinner_modif_evenement
{
    margin-top: 300px;
    margin-bottom: 300px;
}
.image_evenement
{
    min-height: 500px;
    height: 500px;
    width: 100%;
    background-color: black;
    margin-top: 25px;
    margin-bottom: 25px;
}