.alerte_aucune_categorie
{
    margin:25px;
}
.alerte_aucun_evenement
{
    margin-top: 25px;
}
.conteneur_liste_evenement
{
    margin-top:25px;
    vertical-align: middle;
    background-color: #373b3e;
}
.titre_carte_categorie_evenement
{
    color:white;
    margin: 10px;
}
.conteneur_bouton_action
{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hf_bouton_liste
{
    width: 80%;
    min-width: fit-content;
    margin:25px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.conteneur_bouton_pagination
{
    align-items: center;
}
.bouton_ajout_categorie_image
{
    margin: 25px;
}
.bouton_precedent
{
    width: 100%;
}
.bouton_suivant
{
    width: 100%;
}